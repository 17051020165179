<script setup lang="ts">

import {CmsBlock} from "@shopware-pwa/types";

const minHeight = ref("min-height: 250px;");
const subcategoriesContainer = ref(null);

const props = defineProps<{
    content: CmsBlock;
}>();
const category = inject('category');
const noSlidesToShow = category.value?.childCount == 0 && props.content.slots[0].config?.categories.value.length == 0;

// if there are no subcategories and no categories in the configuration, remove the minHeight
if (noSlidesToShow) {
    minHeight.value = "";
}
onMounted(() => {
    useResizeObserver(subcategoriesContainer, () => {
        setTimeout(() => {
            if (subcategoriesContainer.value && subcategoriesContainer.value.clientWidth >= 1024 && !noSlidesToShow) {
                minHeight.value = "min-height: 315px";  
            }
        }, 300);
    });
})
</script>
<template>
<div class="cms-block-subcategories-slider" ref="subcategoriesContainer" :style="minHeight">
    <CmsGenericElement
        v-for="slot in content.slots"
        :key="slot.id"
        :content="slot"
    />
</div>
</template>
